import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "urql";
import PageWrapper from "../../components/PageWrapper";
import Card from "../../components/Industrybox";
import {
  CleaningIcon,
  ConstructionIcon,
  EntertainmentIcon,
  FieldServiceIcon,
  FoodIcon,
  HealthcareIcon,
  HospitalityIcon,
  LogisticsIcon,
  RetailIcon,
} from "../../components/Icons/IndustryIcons";
import { COMPANY_SIZE, INDUSTRIES } from "../../utils/constant";
import SEO from "../../components/Layout/SEO";
import imgAS from "../../assets/image/App_Store_Badge.svg";
import imgGP from "../../assets/image/Google_Play_Store_badge_EN.svg";
import imgTrial from "../../assets/image/svg/signup-trial-customization.svg";
import installAppQrCode from "../../assets/image/install_app_qr_code.png";

import imgEmail from "../../assets/image/svg/welcome-email.svg";

const SAVE_TRIAL_PERSONALIZED = `
  mutation SaveTrialPersonalization($input: SaveTrialPersonalizationInput!) {
    saveTrialPersonalization(input: $input) {
      result
      errors {
        fullMessage
      }
    }
  }
`;

const WelcomeDone = ({ location }) => {
  let fullName = location.state && location.state.fullName;
  let prospectId = location.state && location.state.prospectId;
  let workspaceId = location.state && location.state.workspaceId;

  const [visible, setVisible] = useState(false);
  const [industry, setIndustry] = useState(INDUSTRIES.food_beverages.value);
  const [size, setSize] = useState(COMPANY_SIZE.less_than_ten.value);

  const [saveResult, saveTrialPersonalization] = useMutation(
    SAVE_TRIAL_PERSONALIZED,
  );
  const { handleSubmit } = useForm();
  const onSubmit = () => {
    saveTrialPersonalization({
      input: {
        prospectId: prospectId,
        workspaceId: workspaceId,
        industry: industry,
        companySize: size,
      },
    }).then(() => {
      setVisible(true);
    });
  };

  return (
    <>
      <PageWrapper>
        <SEO title="Sign Up For Camelo"></SEO>

        <div className="sign-page">
          <Container className="pb-25">
            <Row className="justify-content-center pt-25">
              <Col xs="10">
                <div
                  className="form-title text-center"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-delay="100"
                >
                  <img src={imgEmail} alt="" className="w-50" />
                  <h2 className="title gr-text-3 mb-9">
                    Welcome to Camelo, {fullName} 🎉🥳
                  </h2>
                  <h3
                    className="gr-text-6 mb-13 lead"
                    data-aos="fade-up"
                    data-aos-delay="400"
                    data-aos-duration="900"
                  >
                    Click the buttons below or scan the QR code to install
                    Camelo on your devices.
                  </h3>
                  <Row className="justify-content-center">
                    <Col xs="10" sm="8" md="8" lg="8" xl="6">
                      <Row className="justify-content-center align-items-center">
                        <Col xs="8" sm="8" md="8" lg="6" xl="6">
                          <div className="download-btns">
                            <div className="mb-6 text-center">
                              <a href="https://apps.apple.com/app/apple-store/id1577132228?pt=122904235&ct=landing_page&mt=8">
                                <img
                                  src={imgAS}
                                  alt="Apple AppStore Download"
                                  className="w-100"
                                />
                              </a>
                            </div>
                            <p className="text-center">
                              <a href="https://play.google.com/store/apps/details?id=com.camelo">
                                <img
                                  src={imgGP}
                                  alt="Google PlayStore Download"
                                  className="w-100"
                                />
                              </a>
                            </p>
                          </div>
                        </Col>
                        <Col xs="8" sm="8" md="8" lg="5" xl="5">
                          <div className="download-btns">
                            <p className="text-center">
                              <img
                                src={installAppQrCode}
                                alt="Install app QR code"
                                className="w-100 gr-image-box-shadow"
                              />
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <p className="pt-20 pb-10 gr-text-7 text-left text-dark">
                    <strong>What should you do next?</strong>
                    <br />
                    Visit this &nbsp;
                    <a
                      href="http://guide.camelohq.com/en/articles/5736990-getting-started-as-a-manager-business-owner-or-admin"
                      target="_blank"
                      className="btn-link with-icon gr-text-blue gr-text-8 font-weight-bold mt-8"
                    >
                      quick guide
                    </a>
                    &nbsp; to get started or &nbsp;
                    <a
                      href="https://calendly.com/camelo_hq/demo"
                      target="_blank"
                      className="btn-link with-icon gr-text-blue gr-text-8 font-weight-bold mt-8"
                    >
                      book a free demo
                    </a>
                    &nbsp; so our team can walk you through how to use the app.
                  </p>
                  <hr />
                  <Container>
                    {visible === false ? (
                      <>
                        <Row className="justify-content-center pt-5">
                          <Col lg="10">
                            <div
                              className="form-title text-center"
                              data-aos="fade-up"
                              data-aos-duration="750"
                              data-aos-delay="100"
                            >
                              <p
                                className="gr-text-8 text-dark"
                                data-aos="fade-up"
                                data-aos-delay="400"
                                data-aos-duration="900"
                              >
                                Let us know a bit more so we can create the best
                                experience for you
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <p className="text-danger">
                          {saveResult.error &&
                            saveResult.error.graphQLErrors[0].message}
                        </p>
                        <Row
                          data-aos="fade-up"
                          data-aos-delay="500"
                          data-aos-duration="900"
                          className="justify-content-center bg-white border rounded-10 py-lg-16 shadow-1 mb-11"
                        >
                          <Col lg="4">
                            <img src={imgTrial} className="img-fluid" alt="" />
                          </Col>
                          <Col lg="7" className="">
                            <div className="main-block">
                              <div className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-group text-left">
                                    <div className="form-group text-left">
                                      <label className="gr-text-9 font-weight-bold text-blackish-blue">
                                        What is your industry
                                      </label>
                                      <div className="d-flex flex-wrap justify-content-center">
                                        <Card
                                          selectCard={() => {
                                            setIndustry(
                                              INDUSTRIES.food_beverages.value,
                                            );
                                          }}
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.food_beverages.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <FoodIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.food_beverages.label}
                                          </div>
                                        </Card>
                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.hospitality.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.hospitality.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <HospitalityIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.hospitality.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.healthcare.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.healthcare.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <HealthcareIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.healthcare.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.cleaning.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.cleaning.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <CleaningIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.cleaning.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.entertainment.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.entertainment.value
                                          }
                                          width="115px"
                                          height="100px"
                                          padding="10px 0"
                                        >
                                          <div className="w-100 text-center">
                                            <EntertainmentIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.entertainment.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(INDUSTRIES.retail.value)
                                          }
                                          isSelected={
                                            industry === INDUSTRIES.retail.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <RetailIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.retail.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.field_service.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.field_service.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <FieldServiceIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.field_service.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.logistics.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.logistics.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <LogisticsIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.logistics.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(
                                              INDUSTRIES.construction.value,
                                            )
                                          }
                                          isSelected={
                                            industry ===
                                            INDUSTRIES.construction.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div className="w-100 text-center">
                                            <ConstructionIcon />
                                          </div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.construction.label}
                                          </div>
                                        </Card>

                                        <Card
                                          selectCard={() =>
                                            setIndustry(INDUSTRIES.other.value)
                                          }
                                          isSelected={
                                            industry === INDUSTRIES.other.value
                                          }
                                          width="115px"
                                          height="100px"
                                        >
                                          <div
                                            className="w-100 text-center"
                                            style={{ height: "22px" }}
                                          ></div>
                                          <div className="w-100 text-center">
                                            {INDUSTRIES.other.label}
                                          </div>
                                        </Card>
                                      </div>
                                    </div>
                                    <div className="form-group pt-4 text-left">
                                      <label className="gr-text-9 font-weight-bold text-blackish-blue">
                                        How many employees do you need to
                                        manage?
                                      </label>

                                      <div className="d-flex flex-wrap justify-content-center">
                                        <Card
                                          selectCard={() =>
                                            setSize(
                                              COMPANY_SIZE.less_than_ten.value,
                                            )
                                          }
                                          isSelected={
                                            size ===
                                            COMPANY_SIZE.less_than_ten.value
                                          }
                                          width="100px"
                                        >
                                          <div className="text-center">
                                            {COMPANY_SIZE.less_than_ten.label}
                                          </div>
                                        </Card>
                                        <Card
                                          selectCard={() =>
                                            setSize(
                                              COMPANY_SIZE.ten_to_twenty.value,
                                            )
                                          }
                                          isSelected={
                                            size ===
                                            COMPANY_SIZE.ten_to_twenty.value
                                          }
                                          width="100px"
                                        >
                                          <div className="text-center">
                                            {COMPANY_SIZE.ten_to_twenty.label}
                                          </div>
                                        </Card>
                                        <Card
                                          selectCard={() =>
                                            setSize(
                                              COMPANY_SIZE.twenty_to_fifty
                                                .value,
                                            )
                                          }
                                          isSelected={
                                            size ===
                                            COMPANY_SIZE.twenty_to_fifty.value
                                          }
                                          width="100px"
                                        >
                                          <div className="text-center">
                                            {COMPANY_SIZE.twenty_to_fifty.label}
                                          </div>
                                        </Card>
                                        <Card
                                          selectCard={() =>
                                            setSize(
                                              COMPANY_SIZE.more_than_fifty
                                                .value,
                                            )
                                          }
                                          isSelected={
                                            size ===
                                            COMPANY_SIZE.more_than_fifty.value
                                          }
                                          width="100px"
                                        >
                                          <div className="text-center">
                                            {COMPANY_SIZE.more_than_fifty.label}
                                          </div>
                                        </Card>
                                      </div>
                                    </div>
                                    <div className="form-group button-block mb-2 ">
                                      <input
                                        type="submit"
                                        defaultvalue="Done"
                                        value={
                                          saveResult.fetching
                                            ? "Saving..."
                                            : "Done"
                                        }
                                        disabled={saveResult.fetching}
                                        className="form-btn btn btn-primary gr-hover-y w-100"
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="justify-content-center pt-10">
                          <Col lg="10">
                            <div
                              className="form-title text-center"
                              data-aos="fade-up"
                              data-aos-duration="750"
                              data-aos-delay="100"
                            >
                              <p
                                className="gr-text-8"
                                data-aos="fade-up"
                                data-aos-delay="400"
                                data-aos-duration="900"
                              >
                                Thank you for signing up!{" "}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Container>
                  <p className="pt-10 pb-10 gr-text-8 text-left">
                    Need tips on shift planning and time tracking? Check out
                    these blog posts from us 👇
                    <br />
                    <ul className="text-left">
                      <li>
                        <a
                          target="_blank"
                          className="btn-link with-icon gr-text-blue pl-9 gr-text-8 font-weight-bold mt-8"
                          href="https://blog.camelohq.com/shift-planning/"
                        >
                          How to Plan Shifts in an Organized Way
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="btn-link with-icon gr-text-blue pl-9 gr-text-8 font-weight-bold mt-8"
                          href="https://blog.camelohq.com/how-to-manage-shift-work-better/"
                        >
                          How to Manage Shift Work Better
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="btn-link with-icon gr-text-blue pl-9 gr-text-8 font-weight-bold mt-8"
                          href="https://blog.camelohq.com/track-employee-hours/"
                        >
                          How to Plan Shifts in an Organized Way
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="btn-link with-icon gr-text-blue pl-9 gr-text-7 font-weight-bold mt-8"
                          href="https://blog.camelohq.com/how-to-create-attendance-policy-for-small-business/"
                        >
                          How to Create Attendance Policy for Your Small
                          Business
                        </a>
                      </li>
                    </ul>
                  </p>
                  <hr />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default WelcomeDone;
