export const COMPANY_SIZE = {
  less_than_ten: {
    label: "1 - 9",
    value: "less_than_ten",
  },

  ten_to_twenty: {
    label: "10 - 20",
    value: "ten_to_twenty",
  },
  twenty_to_fifty: {
    label: "21 - 50",
    value: "twenty_to_fifty",
  },
  more_than_fifty: {
    label: "50+",
    value: "more_than_fifty",
  },
};

export const INDUSTRIES = {
  food_beverages: {
    label: "Food, Beverages",
    value: "food_beverages",
  },
  hospitality: {
    label: "Hospitality",
    value: "hospitality",
  },
  healthcare: {
    label: "Healthcare",
    value: "healthcare",
  },
  cleaning: {
    label: "Cleaning",
    value: "cleaning",
  },
  entertainment: {
    label: "Entertainment",
    value: "entertainment",
  },
  retail: {
    label: "Retail",
    value: "retail",
  },
  field_service: {
    label: "Field Service",
    value: "field_service",
  },
  logistics: {
    label: "Logistics",
    value: "logistics",
  },
  construction: {
    label: "Construction",
    value: "construction",
  },
  other: {
    label: "Other",
    value: "other",
  },
};
