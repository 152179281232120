import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { typography, width, height, margin, padding } from "styled-system";

const CardSolid = styled.div`
  display: flex;
  justify-content: center;
  justify-item: center;
  align-item: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.1s ease;
  margin: 0 0.5em 0.5em 0;
  &:hover {
    background: #ecf5ff;
  }
  ${props => {
    switch (props.isSelected) {
      case true:
        return css`
          border: 1px solid #473bf0;
          background-color: #ecf5ff;
        `;
      default:
        return css`
          border: 1px solid #eed3ee;
          background-color: #fff;
        `;
    }
  }}
  &:active {
    border-color: transperent;
    box-shadow: 0 0 1px 2px #473bf0;
  }
  ${typography};
  ${width};
  ${height};
  ${margin};
  ${padding}
`;
const Card = ({ children, isSelected = false, selectCard, ...rest }) => {
  return (
    <CardSolid isSelected={isSelected} onClick={() => selectCard()} {...rest}>
      {children}
    </CardSolid>
  );
};
export default Card;
